import axios from 'axios'
import OpenAPIClientAxios from 'openapi-client-axios'

export const { REACT_APP_API_URL } = process.env

if (REACT_APP_API_URL) {
    axios.defaults.baseURL = REACT_APP_API_URL
    axios.defaults.withCredentials = true
    const organization = localStorage.getItem('organization_id')
    if (organization) {
        axios.defaults.params = { organization_id: organization }
    }
}

function setupClient(client) {
    client.interceptors.response.use(
        (response) => response,
        (error) => {
            // TODO: Propagate error to state from here?
            if (
                error?.response?.status > 400 ||
                error?.response?.status <= 499
            ) {
                // console.log('ERROR', error)
                throw error
            } else {
                throw error
            }
        }
    )
    return client
}

var api = new OpenAPIClientAxios({
    definition: `${REACT_APP_API_URL}/openapi.json`,
})
api.getClient().then(function (client) {
    return setupClient(client)
})

export function refreshApiObject() {
    api = new OpenAPIClientAxios({
        definition: `${REACT_APP_API_URL}/openapi.json`,
    })
    api.getClient().then(function (client) {
        return setupClient(client)
    })
}

export async function getAllProjects() {
    const client = await api.getClient()
    const response = await client.get_all_projects_projects_get()
    return response.data
}

export async function getIndividualProject(projectId) {
    const client = await api.getClient()
    const response = await client.get_project_projects__project_id__get(
        { project_id: projectId },
        null
    )
    return response.data
}

export async function createWizardEntry(payload) {
    const client = await api.getClient()
    const response = await client.create_project_from_wizard_wizard_post(
        null,
        payload
    )
    return response.data
}

export async function resetPassword(emailAddress) {
    const client = await api.getClient()
    const response = await client.reset_password_auth_reset_password_post(
        null,
        { email_address: emailAddress }
    )
    return response.data
}

export async function confirmPassword({
    emailAddress,
    password,
    confirmationCode,
}) {
    const client = await api.getClient()
    const response =
        await client.set_password_with_confirmation_auth_confirm_password_post(
            null,
            {
                username: emailAddress,
                password,
                confirmation_code: confirmationCode,
            }
        )
    return response.data
}

export async function getAllAssets() {
    const client = await api.getClient()
    const response = await client.get_assets_asset_get({}, null)
    return response.data
}

export async function createAsset(params) {
    const client = await api.getClient()
    const queryParams = params.engagementId
        ? { engagement_id: params.engagementId }
        : null
    const response = await client.create_asset_asset_post(queryParams, {
        asset_type: params.assetType,
        identifier: params.identifier,
    })
    return response.data
}

export async function deleteAsset(params = { assetId: '' }) {
    const client = await api.getClient()
    const response = await client.delete_asset_asset__asset_id__delete(
        params.assetId
    )
    return response.data
}

export async function addAssetsToEngagement(params) {
    const client = await api.getClient()
    let response = null
    await client
        .add_assets_to_engagement_engagement__engagement_id__assets_post(
            params.engagementId,
            { assets: params.assets }
        )
        .then((res) => {
            response = res.data
        })
        .catch((err) => {
            response = err.response.data
        })
    return response
}

export async function removeAssetsFromEngagement(params) {
    const client = await api.getClient()
    let response =
        await client.remove_assets_from_engagement_engagement__engagement_id__assets_delete(
            params.engagementId,
            { assets: params.assets }
        )

    return response.data
}

export async function deleteAssetBulk(orgId, assets) {
    const client = await api.getClient()
    let response = await client.bulk_delete_assets_asset_bulk_delete(orgId, {
        ids: assets,
    })
    return response
}
export async function deleteCredentialBulk(orgId, credentials) {
    const client = await api.getClient()
    let response = await client.bulk_delete_credential_credential_bulk_delete(
        orgId,
        { ids: credentials }
    )
    return response
}

export async function addCredentialsToEngagement(params) {
    const client = await api.getClient()
    let response = null
    await client
        .add_credentials_to_engagement_engagement__engagement_id__credentials_post(
            params.engagementId,
            { credentials: params.credentials }
        )
        .then((res) => {
            response = res.data
        })
        .catch((err) => {
            response = err.response.data
        })
    return response
}

export async function removeCredentialsFromEngagement(params) {
    const client = await api.getClient()
    const response =
        await client.remove_credentials_from_engagement_engagement__engagement_id__credentials_delete(
            params.engagementId,
            { credentials: params.credentials }
        )
    return response.data
}

export async function getAllReports(params) {
    const client = await api.getClient()
    const response = await client.return_report_reports_get(params, null)
    return response.data
}

export async function postReport(params, id) {
    const client = await api.getClient()
    const formData = new FormData()
    formData.append('file', params.file)
    const response = await client.upload_report_reports_post(
        {
            organization_id: id,
            title: params.title,
            reviewed: params.reviewed,
            type: params.type,
        },
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
    return response
}

export async function deleteReportById(id) {
    const client = await api.getClient()
    const response = await client.delete_report_reports__report_id__delete(id)
    return response
}

export async function getAllFindings(params = {}) {
    const client = await api.getClient()
    const response = await client.return_findings_findings_get(params, null)
    return response.data
}

export async function getAllOutcomes(params = {}) {
    const client = await api.getClient()
    const response = await client.get_outcomes_outcomes_get(params, null)
    return response.data
}

export async function getIndividualFinding(findingId) {
    const client = await api.getClient()
    const response = await client.return_individual_findings__finding_id__get(
        { finding_id: findingId },
        null
    )
    return response.data
}

export async function escalateFinding(findingId) {
    const client = await api.getClient()
    const response = await client.escalate_finding_findings__finding_id__escalate_put(
        { finding_id: findingId },
        null
    )
    return response.data
}

export async function closeFinding(params) {
    const client = await api.getClient()
    const response = await client.close_finding_findings__finding_id__close_put(
        params.id,
        {
            reason: params.reason,
            reason_other: params.reason_other
        },
        null
    )
    return response.data
}

export async function getDashboardFindings() {
    const client = await api.getClient()
    const response =
        await client.view_findings_aggregate_findings_dashboard_get({}, null)
    return response.data
}

export async function getCredentials() {
    const client = await api.getClient()
    const response = await client.get_credentials_credential_get(
        { as_json: true },
        null
    )
    return response.data
}

export async function createAWSCredential(params) {
    const client = await api.getClient()
    const response = await client.create_aws_credential_credential_AWS_post(
        params.engagementId ? { engagement_id: params.engagementId } : null,
        {
            name: params.name,
            role_arn: params.roleArn,
            access_key: params.accessKey,
            secret_key: params.secretKey,
            session_token: params.sessionToken,
        }
    )
    return response.data
}

export async function createAZURECredential(params) {
    const client = await api.getClient()
    const response = await client.create_azure_credential_credential_AZURE_post(
        params.engagementId ? { engagement_id: params.engagementId } : null,
        {
            name: params.name,
            tenant_id: params.tenantId,
            client_id: params.clientId,
            client_secret: params.clientSecret,
            subscription_id: params.subscriptionId,
        }
    )
    return response.data
}
export async function createGCPCredential(params) {
    const client = await api.getClient()
    const response = await client.create_gcp_credential_credential_GCP_post(
        params.engagementId ? { engagement_id: params.engagementId } : null,
        {
            ...params,
        }
    )
    return response.data
}

export async function deleteCredential(params = { credentialId: '' }) {
    const client = await api.getClient()
    const response =
        await client.delete_credential_credential__credential_id__delete(
            params.credentialId
        )
    return response.data
}

export async function getCurrentUser() {
    const client = await api.getClient()
    client.defaults.headers.common.Authorization = !client.defaults.headers
        .common.Authorization
        ? axios.defaults.headers.common.Authorization
        : client.defaults.headers.common.Authorization
    const response = await client.get_current_user_user_self_get({}, null)

    return response.data
}

export async function getOrganization() {
    const client = await api.getClient()
    const response = await client.get_organizations_self_organizations_self_get(
        {},
        null
    )
    return response.data
}

export async function getAllOrganizations() {
    const client = await api.getClient()
    const response = await client.get_organizations_organizations_get({}, null)
    return response.data
}

export async function updateUserDetails(
    params = { firstName: '', lastName: '', emailAddress: '' }
) {
    const client = await api.getClient()
    const response = await client.update_self_user_self_put(null, {
        first_name: params.firstName,
        last_name: params.lastName,
        email_address: params.emailAddress,
    })
    return response.data
}

export async function updateUserPassword(params = {}) {
    const client = await api.getClient()
    const response = await client.update_password_user_update_password_post(
        null,
        {
            previous_password: params.oldPassword,
            proposed_password: params.newPassword,
        }
    )
    return response.data
}

export async function getAllUsers() {
    const client = await api.getClient()
    const response = await client.get_all_users_user__get({}, null)
    return response.data
}

export async function createUser(params) {
    const client = await api.getClient()
    let response = null
    await client
        .create_new_user_user_post(params.role, {
            first_name: params.firstName,
            last_name: params.lastName,
            email_address: params.emailAddress,
            role: params.role,
            organization: params.organizationId,
        })
        .then((res) => {
            response = res
        })
        .catch((err) => {
            response = err.response
        })
    return response
}

export async function deleteUser(params) {
    const client = await api.getClient()
    const response = await client.delete_user_user__user_id__delete(params.id, {
        user_id: params.id,
    })
    return response.data
}

export async function getAllQuotes() {
    const client = await api.getClient()
    const response = await client.get_all_quotes_quotes_get({}, null)
    return response.data
}

export async function getAllMetrics() {
    const client = await api.getClient()
    const response = await client.get_metrics_metrics_get({}, null)
    return response.data
}

export async function uploadOrganizationLogo(params) {
    const client = await api.getClient()
    const formData = new FormData()
    formData.append('file', params.file)
    try {
        const response =
            await client.upload_self_org_logo_organizations_logo_post(
                null,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
        return response.data
    } catch (error) {
        return error.response
    }
}

export async function getOrganizationLogo() {
    const client = await api.getClient()
    const response = await client.fetch_logo_organizations_self_logo_get(
        {},
        null
    )
    return response.data
}

export async function getSocialCampaigns(params = {}) {
    const client = await api.getClient()
    const response = await client.get_my_social_campaigns_social_campaign_get(
        params,
        null
    )
    return response.data
}

export async function deleteSocialCampaign(campaignId) {
    const client = await api.getClient()
    let response = null
    await client
        .delete_social_campaign_social_campaign__social_campaign_id__delete(
            campaignId
        )
        .then((res) => {
            response = res
        })
        .catch((err) => {
            response = err.response
        })
    return response
}

export async function getSocialCampaignMonthlyAggregates(params = {}) {
    const client = await api.getClient()
    let response = null
    await client
        .get_monthly_campaign_aggregates_social_campaign_monthly_aggregates_get(
            params
        )
        .then((res) => {
            response = res
        })
        .catch((err) => {
            response = err.response
        })
    return response
}

export async function acceptEula() {
    const client = await api.getClient()
    const response = await client.accept_eula_user_self_accept_eula_put()
    return response.data
}

export async function getAllEngagements() {
    const client = await api.getClient()
    const response = await client.get_engagements_engagement_get()
    return response
}

export async function exportFindings(params) {
    const client = await api.getClient()
    const response = await client.export_findings_findings_export_post(
        null,
        params
    )
    return response.data
}

export async function getEngagement(engagementId) {
    const client = await api.getClient()
    const response =
        await client.get_single_engagement_engagement__engagement_id__get(
            engagementId,
            null
        )
    return response.data
}

export async function getAllContacts() {
    const client = await api.getClient()
    const response = await client.get_contacts_contact_get(null, {})
    return response.data
}

export async function createContact(params) {
    const client = await api.getClient()
    const response = await client.create_contact_contact_post(null, {
        first_name: params.firstName,
        last_name: params.lastName,
        phone_number: params.phoneNumber,
        title: params.title,
        email: params.email,
        type: params.type,
    })
    return response.data
}

export async function updateContact(params) {
    const client = await api.getClient()
    const response = await client.change_contacts_contact__contact_id__put(
        params.id,
        {
            first_name: params.firstName,
            last_name: params.lastName,
            phone_number: params.phoneNumber,
            title: params.title,
            email: params.email,
            type: params.type,
        }
    )
    return response.data
}

export async function deleteContact(contactId) {
    const client = await api.getClient()
    const response = await client.delete_contact_contact__contact_id__delete(
        contactId,
        null
    )
    return response.data
}
export async function deleteFindings(params) {
    const client = await api.getClient()
    const response = await client.bulk_delete_findings_findings_bulk_delete(
        null,
        params
    )
    return response.data
}
export async function getCriticalityByFindings() {
    const client = await api.getClient()
    const response =
        await client.get_finding_by_criticality_findings_aggregates_get()
    return response.data
}

export async function fetchOrganizationLogo() {
    const client = await api.getClient()
    const response = await client.fetch_logo_organizations_logo_get()
    return response.data
}
export async function uploadAsset(formData) {
    const client = await api.getClient()
    const response = await client.upload_asset_asset_upload_post(
        null,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
    return response.data
}
