import React, { useEffect, useState } from 'react'
import { useLocation, matchPath, Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    List,
    ListSubheader,
    Typography,
    makeStyles,
} from '@material-ui/core'
import DefaultOrgLogo from 'src/theme/s2/UVLogo_Primary_Purple.svg'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import NavItem from './NavItem'
import { useOrganization } from 'src/actions/customHooks'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as HomeSVG } from 'src/theme/s2/UVC_Icon_Home.svg'
import { ReactComponent as AttackSVG } from 'src/theme/s2/UVC_Icon_attack.svg'
import { ReactComponent as DataSheetsSVG } from 'src/theme/s2/UVC_Icon_data-sheets.svg'
import { ReactComponent as CrossPlatformSVG } from 'src/theme/s2/UVC_Icon_cross-platform.svg'
import { ReactComponent as UVC_dashboard } from 'src/theme/s2/UVC_Icon_dashboard.svg'
import { ReactComponent as Workbench } from 'src/theme/s2/UVC_Icon_Adversary_Prevention.svg'
import { ReactComponent as AlertsSVG } from 'src/theme/s2/UVC_Icon_hunting.svg'
// import { ReactComponent as VMaas } from 'src/theme/s2/UVC_Icon_broad-visibility.svg'
import { ReactComponent as Phishing } from 'src/theme/s2/UVC_Icon_phishing.svg'
import { fetchOrganizationLogo } from 'src/actions/openapi'
import { BlackOnWhiteTooltip } from 'src/components/Tooltip'
import { useGlobalState } from 'src/context/useGlobalState';
const SOCIAL_SERVICES = ['Phishing']
const VMASS_SERVICES = ['Workbench']

const sections = [
    {
        subheader: 'Home',
        icon: HomeSVG,
        href: '/',
        items: [],
    },
    {
        subheader: 'Dashboards',
        icon: UVC_dashboard,
        href: process.env.REACT_APP_SOC_OS_URL,
        external: true,
        target: '_self',
        items: [],
    },
    {
        subheader: 'Reports',
        icon: DataSheetsSVG,
        href: '/reports',
        items: [],
    },
    {
        subheader: 'Signals',
        icon: AlertsSVG,
        href: '/signals',
        items: [],
    },
    {
        subheader: 'Outcomes',
        icon: AttackSVG,
        href: '/outcomes',
        items: [],
    },
    {
        subheader: 'Workbench',
        icon: Workbench,
        items: [
            // {
            //     title: 'VMaaS', // this will probably change to vuln and have the href updated
            //     href: process.env.REACT_APP_VMAAS_URL,
            //     external: true,
            //     target: '_blank',
            //     disabled: true,
            // },

            // {
            //     // Voodoo
            //     title: 'Operate',
            //     href: 'https://voodoo.stage2sec.io/admin/',
            //     external: true,
            //     target: '_blank',
            // },
            {
                title: 'Investigate',
                href: process.env.REACT_APP_CASES_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
            {
                title: 'Intel',
                href: process.env.REACT_APP_SOC_OS_CTI_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
            {
                title: "Threat Sources",
                href: process.env.REACT_APP_SOC_OS_THREAT_SOURCES_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
            {
                title: "Source Reports",
                href: process.env.REACT_APP_SOC_OS_SOURCE_REPORTS_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
            {
                title: 'Detections',
                href: process.env.REACT_APP_SOC_BUILD_DETECTIONS_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
            {
                title: 'Search',
                href: process.env.REACT_APP_SOC_SEARCH_URL,
                target: '_blank',
                external: true,
                disabled: true,
            },
        ],
    },
    // {
    //     subheader: 'Content',
    //     icon: MDR,
    //     items: [
    //         {
    //             title: 'Intel',
    //             href: process.env.REACT_APP_SOC_OS_CTI_URL,
    //             external: true,
    //             target: '_self',
    //             disabled: true,
    //         },
    //         {
    //             title: "Threat Sources",
    //             href: process.env.REACT_APP_SOC_OS_THREAT_SOURCES_URL,
    //             external: true,
    //             target: '_self',
    //             disabled: true,
    //         },
    //         {
    //             title: "Source Reports",
    //             href: process.env.REACT_APP_SOC_OS_SOURCE_REPORTS_URL,
    //             external: true,
    //             target: '_self',
    //             disabled: true,
    //         },
    //         {
    //             title: 'Detections',
    //             href: process.env.REACT_APP_SOC_BUILD_DETECTIONS_URL,
    //             external: true,
    //             target: '_self',
    //             disabled: true,
    //         },
    //     ],
    // },

    {
        subheader: 'Phishing',
        icon: Phishing,
        href: '/phishing',
        disabled: false,
        items: []
    },
    {
        subheader: 'Assets',
        icon: CrossPlatformSVG,
        items: [
            {
                title: 'Digital Assets',
                href: '/assets',
                external: false,
                disabled: false,
            },
            {
                title: 'Hosts',
                href: process.env.REACT_APP_SOC_BUILD_HOSTS_URL,
                external: true,
                target: '_self',
                disabled: true,
            },
        ],
    },

    // {
    //     subheader: 'Services',
    //     icon: ServiceSVG,

    //     items: [
    //         {
    //             href: '/services/',
    //             title: 'Service Requests',
    //             disabled: false,
    //         },
    //         {
    //             title: 'Request Service',
    //             href: '/services/create',
    //         },
    //     ],
    // },
]





const borderMap = {
    // Dashboard: '#6314FF',
    // RTaaS: '#8F71F8',
    // VMaaS: '#6314FF',
    // Assets: '#8F71F8',
    // Alerts: '#6314FF',classes
    // Reports: '#8F71F8',
    // MDR: '#6314FF',
    // 'Request Service': '#8F71F8',
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    companyLogo: {
        cursor: 'pointer',
        width: 80,
        height: 80,
    },
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.secondary.navTitle,
        padding: '10px 8px !important',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    companyContainer: {
        margin: '0 auto',
        width: '75%',
        textAlign: 'center',
    },
    companyName: {
        color: theme.palette.text.primary,
    },
    companyPackage: {
        color: theme.palette.text.secondary,
    },
    companyServices: {
        background: theme.palette.primary.purple,
        color: theme.palette.text.primary,
        padding: '1px',
        borderRadius: '11px',
        fontSize: 'small',
    },
    headerButton: {
        height: '120px',
        width: '120px',
    },
    chevronButton: {
        position: 'absolute',
        right: '0px',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        width: '35px',
        height: 'auto'
    },
    link: {
        'text-decoration': 'none',
    },
    toolLink: {
        color: theme.palette.link.main,
        '&:visited': {
            color: theme.palette.link.active,
            // textDecoration: 'underline',
        },
        '&:hover': {
            color: theme.palette.link.hover,
            textDecoration: 'underline',
        },
    }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles()
    const location = useLocation()
    const organization = useOrganization()
    const [logo, setLogo] = useState()
    const [open, setOpen] = useState({})
    const { setServiceEnabled } = useGlobalState();
    const tooltipTitle = (item, category) => {
        return item.disabled && (
            <React.Fragment>
                Ask us about{' '}
                {[...SOCIAL_SERVICES, ...VMASS_SERVICES].includes(item.title)
                    ? item.title
                    : category}{' '}
                to improve your security stance!{' '}
                <br></br>
                <a
                    className={classes.toolLink}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.uvcyber.com/contact"
                >
                    Contact us for more information or questions
                </a>
            </React.Fragment>
        )
    }
    // const handleOnMouseover = (e, bool) => {
    //     setOpen({
    //         [e]: bool,
    //     })
    // }
    const handleOnTouchStart = (e) => {
        setOpen({
            [e]: !open[e],
        })
    }
    const reduceChildRoutes = ({
        category,
        acc,
        pathname,
        item,
        depth,
        onMobileClose,
    }) => {
        const key = item.title + depth

        if (item.items) {
            const open = matchPath(pathname, {
                path: item.href,
                exact: true,
            })

            acc.push(
                <BlackOnWhiteTooltip
                    interactive={true}
                    title={item.disabled ? tooltipTitle(item, item.title) : ''}
                    followcursor="true"
                    key={key}
                    placement="right-end"
                >
                    <NavItem
                        depth={depth}
                        disabled={item.disabled}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        open={Boolean(open)}
                        title={item.title}
                        external={item.external}
                        onMobileClose={onMobileClose}
                    >
                        {renderNavItems({
                            depth: depth + 1,
                            pathname,
                            items: item.items,
                            onMobileClose: onMobileClose,
                        })}
                    </NavItem>
                </BlackOnWhiteTooltip>
            )
        } else {
            acc.push(
                <BlackOnWhiteTooltip
                    interactive={true}
                    title={item.disabled ? tooltipTitle(item, item.title) : ''}
                    followcursor="true"
                    key={key}
                    placement="right-end"
                >
                    <span>
                        <NavItem
                            depth={depth}
                            disabled={item.disabled}
                            href={item.href}
                            icon={item.icon}
                            info={item.info}
                            key={key + depth}
                            target={item.target}
                            title={item.title}
                            external={item.external}
                            onMobileClose={onMobileClose}
                        />
                    </span>
                </BlackOnWhiteTooltip>
            )
        }
        return acc
    }
    const renderNavItems = ({
        category,
        items,
        pathname,
        depth = 0,
        onMobileClose,
    }) => {
        return (
            <List disablePadding>
                {items.reduce(
                    (acc, item) =>
                        reduceChildRoutes({
                            category,
                            acc,
                            item,
                            pathname,
                            depth,
                            onMobileClose,
                        }),
                    []
                )}
            </List>
        )
    }
    // useEffect(() => {
    //     if (openMobile && onMobileClose) {
    //         onMobileClose()
    //     }
    // }, [location.pathname])

    const updateServices = (ACCESS) => {

        sections.forEach((_section) => {
            if (ACCESS.length) {
                if (
                    (_section.subheader === 'Content' &&
                        ACCESS.includes(_section.subheader)) ||
                    (_section.subheader === 'Workbench' &&
                        ACCESS.includes(_section.subheader)) ||
                    (_section.subheader === 'Assets' &&
                        ACCESS.includes(_section.subheader))
                ) {
                    _section.disabled = false
                    _section.items.forEach((_item) => (_item.disabled = false))
                } else if (_section.subheader !== 'MDR') {
                    _section.items.forEach((_item) => {
                        if (VMASS_SERVICES.includes(_item.title)) {
                            _item.disabled =
                                ACCESS.includes('VMAAS')
                                    ? false
                                    : true
                        } else if (SOCIAL_SERVICES.includes(_item.title)) {
                            _item.disabled = ACCESS.includes('SOCIAL')
                                ? false
                                : true
                        }
                    })
                }
            } else {
                _section.items.forEach((_item) => {
                    if (
                        [...SOCIAL_SERVICES, ...VMASS_SERVICES].includes(
                            _item.title
                        )
                    ) {
                        _item.disabled = true
                    }
                })
            }
        })
    }
    useEffect(() => {
        if (Object.keys(organization).length) {
            let SERVICE_ACCESS = []
            organization.organizationservices.forEach(function (_r) {
                // we might change this
                if (_r.service === 'MDR') {
                    SERVICE_ACCESS.push('Content', 'Assets', 'Workbench', 'Dashboards')
                }
                SERVICE_ACCESS.push(_r.service)
            })
            setServiceEnabled(SERVICE_ACCESS)
            updateServices(SERVICE_ACCESS)
        }
    }, [organization])

    useEffect(() => {
        fetchOrganizationLogo()
            .then((data) => {
                setLogo(data)
            })
            .catch(() => {
                setLogo(DefaultOrgLogo)
            })
    }, [])

    const content = (
        <Box overflow="hidden" display="flex" flexDirection="column">
            <Hidden lgUp>
                <IconButton
                    className={classes.chevronButton}
                    onClick={onMobileClose}
                >
                    <ChevronLeftIcon />
                </IconButton>
            </Hidden>
            <Box p={2}>
                <Box mt={2} className={classes.companyContainer}>
                    <img
                        src={logo}
                        className={classes.companyLogo}
                        alt={'company_logo'}
                    />
                    <Typography className={classes.companyName}>
                        {organization?.companyName}
                    </Typography>
                    {/* <Typography className={classes.companyPackage}>
                        {organization?.package} Package
                    </Typography> */}
                    {/* we might remove serviceEnabled */}
                    {/* {serviceEnabled.length ? (
                        <Typography className={classes.companyServices}>
                            {serviceEnabled.toString()}
                        </Typography>
                    ) : undefined} */}
                </Box>
            </Box>
            <Divider width={'90%'} style={{ margin: '0 8px' }} />
            <Box
                p={2}
                sx={{ overflowY: 'auto' }}
                position="relative"
                height="100%"
            >
                <PerfectScrollbar options={{ suppressScrollX: true }}>

                    {sections.map((section, index) => {
                        return (
                            <List
                                onClick={(e) => {
                                    section.disabled ? e.preventDefault() : handleOnTouchStart(section.subheader)
                                }
                                }
                                key={index}
                                style={{
                                    borderLeft: `3px solid ${borderMap[section.subheader]
                                        }`,
                                    paddingLeft: '10px',
                                    marginBottom: '8px',
                                }}
                                subheader={
                                    <ListSubheader disableGutters disableSticky >
                                        <BlackOnWhiteTooltip
                                            interactive={true}
                                            title={section.disabled ? tooltipTitle(section, section.subheader) : ''}
                                            followcursor="true"
                                            key={index}
                                            placement="right-end"
                                        >

                                            <span className={classes.item}>
                                                {section.href ? (
                                                    section.external ? (
                                                        <Button
                                                            disabled={section.disabled}
                                                            className={
                                                                classes.button
                                                            }
                                                            href={section.href}
                                                            rel="noopener"
                                                            target={section.target}
                                                            style={{
                                                                paddingLeft: 0,
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    openMobile &&
                                                                    onMobileClose
                                                                ) {
                                                                    onMobileClose()
                                                                }
                                                            }}
                                                        >
                                                            <section.icon
                                                                className={
                                                                    classes.icon
                                                                }
                                                            />{section.disabled}

                                                            <span
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                {section.subheader}
                                                            </span>
                                                            {section.items
                                                                .length ? (
                                                                open[
                                                                    section
                                                                        .subheader
                                                                ] ? (
                                                                    <ExpandLessIcon />
                                                                ) : (
                                                                    <ExpandMoreIcon />
                                                                )
                                                            ) : null}
                                                        </Button>
                                                    ) : (
                                                        <Link
                                                            exact="true"
                                                            to={section.href}
                                                            className={classes.link}
                                                        >

                                                            <Button
                                                                className={
                                                                    classes.button
                                                                }
                                                                onClick={(e) => {
                                                                    section.disabled ? e.preventDefault() : handleOnTouchStart(section.subheader)
                                                                    if (section.items.length === 0) {
                                                                        if (openMobile && onMobileClose) {
                                                                            onMobileClose()
                                                                        }

                                                                    }
                                                                }
                                                                }

                                                                style={{
                                                                    paddingLeft: 0,
                                                                }}
                                                            >
                                                                <section.icon
                                                                    className={
                                                                        classes.icon
                                                                    }
                                                                />
                                                                {section.disabled}
                                                                <span
                                                                    className={
                                                                        classes.title
                                                                    }
                                                                >
                                                                    {
                                                                        section.subheader
                                                                    }
                                                                </span>
                                                                {section.items
                                                                    .length ? (
                                                                    open[
                                                                        section
                                                                            .subheader
                                                                    ] ? (
                                                                        <ExpandLessIcon />
                                                                    ) : (
                                                                        <ExpandMoreIcon />
                                                                    )
                                                                ) : null}
                                                            </Button>
                                                        </Link>
                                                    )
                                                ) : (
                                                    <Button
                                                        disabled={section.disabled}
                                                        className={classes.button}
                                                        onClick={(e) => {
                                                            section.disabled ? e.preventDefault() : handleOnTouchStart(section.subheader)
                                                        }
                                                        }
                                                        style={{ paddingLeft: 0 }}
                                                    >
                                                        <section.icon
                                                            className={classes.icon}
                                                        />

                                                        <span
                                                            className={
                                                                classes.title
                                                            }
                                                        >
                                                            {section.subheader}
                                                        </span>
                                                        {section.items.length ? (
                                                            open[
                                                                section.subheader
                                                            ] ? (
                                                                <ExpandLessIcon />
                                                            ) : (
                                                                <ExpandMoreIcon />
                                                            )
                                                        ) : null}
                                                    </Button>
                                                )}
                                                <Collapse
                                                    in={open[section.subheader]}
                                                >
                                                    {renderNavItems({
                                                        category: section.subheader,
                                                        items: section.items,
                                                        pathname: location.pathname,
                                                        onMobileClose:
                                                            onMobileClose,
                                                    })}
                                                </Collapse>
                                            </span>
                                        </BlackOnWhiteTooltip>
                                    </ListSubheader>
                                }
                            ></List>
                        )
                    })}
                </PerfectScrollbar>
            </Box >
        </Box >
    )

    return (
        <React.Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </React.Fragment>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

export default NavBar
